<template>
  <Gallery3D
    :show="!isInterior"
    :folder="gallery.folder"
    :color="gallery.colors[gallery.colorActive].folder"
    :file="gallery.colors[gallery.colorActive].fileName[placeholderIndex]"
    :breakpoints="gallery.breakpoints"
    v-slot="{ currentBreakpointIndex, hideAllPopover }"
  >
    <template v-for="(breackpoint, index) in gallery.breakpoints" :key="index">
      <GalleryPopover
        v-for="(item, index2) in breackpoint.items"
        :key="index2"
        :top="item.offset.top"
        :left="item.offset.left"
        :topMobile="item.offsetMobile.top"
        :leftMobile="item.offsetMobile.left"
        :title="item.title"
        :desc="item.desc"
        :media="item.media"
        :gtmReachGoal="item.gtmReachGoal"
        :currentBreakpointIndex="currentBreakpointIndex"
        :breakpointIndex="index"
        :hideAll="hideAllPopover"
        @show="onShowPopover"
      />
    </template>
    <div
      class="popover-backdrop"
      :class="{ popover_state_show: showPopover }"
    ></div>
  </Gallery3D>
  <PanoramaInterior
    :panoramaOptions="panorama.options"
    :infospots="panorama.infospots"
    :show="isInterior"
  >
    <PanoramaInteriorInfospot
      v-for="(item, index) in panorama.infospots"
      :key="index"
      :infospot="item"
    />
  </PanoramaInterior>
</template>

<script>
import { mapState } from "vuex";

import Gallery3D from "@/components/Gallery3D.vue";
import GalleryPopover from "@/components/GalleryPopover.vue";
import PanoramaInterior from "@/components/PanoramaInterior.vue";
import PanoramaInteriorInfospot from "@/components/PanoramaInteriorInfospot.vue";

export default {
  name: "CarComponent",
  components: {
    Gallery3D,
    GalleryPopover,
    PanoramaInterior,
    PanoramaInteriorInfospot,
  },
  props: {
    gallery: Object,
    panorama: Object,
  },
  computed: {
    ...mapState(["placeholder"]),
    placeholderIndex() {
      if (this.placeholder === "two_cars") {
        return "light";
      }

      return "light2";
    },
    isInterior() {
      const { mode } = this.$route.query;
      return mode === "interior";
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  created() {
    this.setInfopostsClassName();
  },
  methods: {
    setInfopostsClassName() {
      this.panorama.infospots.forEach((element, index) => {
        element.className = `infospot_${index}`;
      });
    },
    onShowPopover(state) {
      this.showPopover = state;
    },
  },
};
</script>

<style scoped lang="scss">
.popover-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
